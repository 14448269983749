import React from 'react';

const IconExternal = () => (
  <svg height="390pt" viewBox="-49 0 390 390" width="390pt" xmlns="http://www.w3.org/2000/svg">
    <path d="m234.601562 108.101562c-27.613281-.011718-49.992187-22.390624-50-50v-58.101562h-154.601562c-16.546875.046875-29.953125 13.453125-30 30v330c.046875 16.546875 13.453125 29.953125 30 30h232.699219c16.550781-.046875 29.953125-13.453125 30-30v-251.898438zm-180.5 44.898438h108.097657c5.523437 0 10 4.476562 10 10s-4.476563 10-10 10h-108.097657c-5.523437 0-10-4.476562-10-10s4.476563-10 10-10zm194.597657 171.300781h-194.597657c-5.523437 0-10-4.476562-10-10 0-5.523437 4.476563-10 10-10h194.597657c5.523437 0 10 4.476563 10 10 0 5.523438-4.476563 10-10 10zm0-75.699219h-194.597657c-5.523437 0-10-4.480468-10-10 0-5.523437 4.476563-10 10-10h194.597657c5.523437 0 10 4.476563 10 10 0 5.519532-4.476563 10-10 10zm0 0" />
    <path d="m234.601562 88.101562h44.199219l-74.199219-74v44c.011719 16.5625 13.433594 29.984376 30 30zm0 0" />
  </svg>
);

export default IconExternal;
