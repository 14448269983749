module.exports = {
  siteTitle: 'Kabir Kalsi',
  siteDescription:
    'Kabir Kalsi is a mechanical engineer with a passion for building & designing scalable hardware.',
  siteKeywords: 'Kabir Kalsi, mechanical engineer, product development',
  siteUrl: 'https://kabirkalsi.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-179383085-1',
  googleVerification: 'hd6WeTMavGOwwQ-BQ6qvSwiDrVCw61PEl6ereG8R8TQ',
  name: 'Kabir Kalsi',
  location: 'Boston, MA',
  email: 'kabirkalsi98@gmail.com',
  github: 'https://github.com/kabirk',
  twitterHandle: '@kalsi_kabir',
  socialMedia: [
    {
      name: 'External',
      url: 'https://drive.google.com/file/d/1dtEw3ShmaBFQJAja1gq3ukOnUaeVQVzp/view?usp=sharing',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/kabirkalsi/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/kalsi_kabir',
    },
  ],

  navLinks: [
    {
      name: 'Work',
      url: '/#work',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
