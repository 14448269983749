import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { theme, mixins, media, Section } from '@styles';
const { colors, fonts, fontSizes, navDelay, loaderDelay } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  ${media.tablet`padding-top: 150px;`};
  div {
    width: 100%;
  }
`;
const StyledProjectName = styled.h1`
  font-size: 56px;
  align-self: center;
  font-family: ${fonts.Brown};
  color: ${colors.lightestSlate};
  ${media.tablet`font-size: 56px;`};
  ${media.thone`color: ${colors.lightNavy};`};
  a {
    ${media.tablet`display: block;`};
  }
`;
const StyledSubtitle = styled.h3`
  font-size: 34px;
  text-align: center;
  align-self: center;
  line-height: 1.1;
  font-family: ${fonts.Brown};
  color: ${colors.slate};
  ${media.tablet`font-size: 30px;`};
  ${media.phablet`font-size: 20px;`};
  ${media.phone`font-size: 20px;`};
`;
const StyledDescription = styled.div`
  position: relative;
  z-index: 2;
  color: ${colors.lightNavy};
  font-family: ${fonts.Brown};
  font-size: ${fontSizes.lg};
  border-radius: ${theme.borderRadius};
  ${media.thone`
    background-color: transparent;
    padding: 20px 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `};
  a {
    ${mixins.inlineLink};
  }
`;
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 50%;
  align-self: center;
  font-family: ${fonts.Brown};
  position: relative;
  margin: 64px;
  ${media.thone`
    margin: 32px;
  `};
`;

const StyledDoubleImage = styled(Img)`
  display: flex;
  flex-direction: row;
  jusitfy-content: space-between;
`;

const Project = ({ data, location }) => {
  const { state = {} } = location;
  const { name } = state;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  let frontmatterIndex = 0;
  data.forEach((project, index) => {
    if (project.node.frontmatter.name === name) {
      frontmatterIndex = index;
    }
  });

  const { frontmatter, html } = data[frontmatterIndex].node;

  const projectName = () => (
    <StyledProjectName style={{ transitionDelay: '300ms' }}>{frontmatter.name}</StyledProjectName>
  );

  const subtitle = () => (
    <StyledSubtitle style={{ transitionDelay: '500ms' }}>{frontmatter.headline}</StyledSubtitle>
  );

  const cover = () => (
    <StyledFeaturedImg
      style={{ transitionDelay: '700ms' }}
      fluid={frontmatter.cover.childImageSharp.fluid}
      alt={frontmatter.name}
    />
  );

  const description = () => (
    <StyledDescription
      style={{ transitionDelay: '900ms' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );

  const items = [projectName, subtitle, cover, description];

  return (
    <StyledContainer>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              {item}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledContainer>
  );
};

Project.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default Project;
